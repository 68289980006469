import HeaderAvatar from '../components/HeaderAvatar'

const HeaderAvatarContainer = () => {

  return (
    <HeaderAvatar>
    </HeaderAvatar>
  )
}

export default HeaderAvatarContainer